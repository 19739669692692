import { gql } from '@apollo/client';

export const ASSIGN_PRICE_PROFILE_MUTATION = gql`
  mutation assignPriceProfile($businessId: ID!, $priceProfileId: String!, $oldPriceProfileId: String!) {
    assignPriceProfile(
      businessId: $businessId
      oldPriceProfileId: $oldPriceProfileId
      priceProfileId: $priceProfileId
    ) {
      id
    }
  }
`;

export const ASSIGN_OFFERING_OPTION_55_MUTATION = gql`
  mutation AssignPriceProfile($businessId: String!) {
    assignOfferingOption55(businessId: $businessId) {
      response
    }
  }
`;