import { get } from 'lodash';

import { CopyToClipboardByClick } from 'src/components/common/CopyToClipboard/CopyToClipboard';
import { TxnPayout } from 'src/containers/txnLookup/constants/txnConstants';
import { ViewDetails } from 'src/components/common/ViewDetails/ViewDetails';
import { stringifyDate } from 'src/helpers/dateTimeHelpers';
import PayoutTime from '../components/PayoutTime';
import TransferStatus from '../components/TransferStatus';

interface PayoutsColumnsConfigProps {
  editorRouteBase: string;
}

export const getAccountPayoutsColumnsConfig = ({ editorRouteBase }: PayoutsColumnsConfigProps) => [
  {
    attr: 'id',
    label: 'Payout ID',
    customRenderer: (payoutItem: TxnPayout) => <CopyToClipboardByClick text={payoutItem.id} />,
  },
  {
    attr: 'transferId',
    label: 'Transfer Id',
    customRenderer: (payoutItem: TxnPayout) => <CopyToClipboardByClick text={payoutItem.transferId} />,
  },
  {
    attr: 'transferStatus',
    label: 'Transfer Status',
    customColumnStyle: () => ({ width: 100 }),
    customRenderer: (payoutItem: TxnPayout) => <TransferStatus transferStatus={payoutItem?.transferStatus} />,
  },
  {
    attr: 'subTotalAmount',
    label: 'SubTotal Amount',
    customColumnStyle: () => ({ textAlign: 'right', width: 100 }),
  },
  {
    attr: 'payoutAmount',
    label: 'Payout Amount',
    customColumnStyle: () => ({ textAlign: 'right', width: 100 }),
  },
  {
    attr: 'currency',
    label: 'Curr',
    customColumnStyle: () => ({ width: 50 }),
  },
  {
    attr: 'payoutTime',
    label: 'Payout Time',
    customRenderer: (payoutItem: TxnPayout) => <PayoutTime payoutItem={payoutItem} />,
    customColumnStyle: () => ({ width: 190 }),
  },
  {
    attr: 'effectiveAt',
    label: 'Effective At',
    customRenderer: (item: TxnPayout) => stringifyDate(get(item, 'effectiveAt')),
    customColumnStyle: () => ({ width: 170 }),
  },
  {
    attr: 'actions',
    label: 'Actions',
    disableRowClick: true,
    customColumnStyle: () => ({ width: '9rem', textAlign: 'center' }),
    customRenderer: (item: TxnPayout) => {
      return <ViewDetails href={`${editorRouteBase}/${encodeURIComponent(item.id)}`} />;
    },
  },
];
