import styled from '@emotion/styled';
import { get } from 'lodash';

const TRANSFER_STATUS_COLOR = {
  FAILED: '#d11d13',
  SUBMITTED: 'green',
  IN_PROGRESS: '#2141d1',
  PENDING: 'orange',
  default: 'default',
};

export default function TransferStatus({ transferStatus = '' }: { transferStatus: string }) {
  const color = get(TRANSFER_STATUS_COLOR, transferStatus, TRANSFER_STATUS_COLOR.default);

  return <TransferStatusWrapper color={color}>{transferStatus}</TransferStatusWrapper>;
}

const TransferStatusWrapper = styled('span')<{ color?: string }>`
  color: ${props => props.color || '#default'};
`;
