import styled from '@emotion/styled';

export const NodeWrapper = styled('div')<{
  color?: string;
  isActive?: boolean;
  onClick?: (event: any) => void;
}>`
  background-color: ${p => `${p.color}CC`};
  border: 2px solid ${p => (p.isActive ? 'rgba(0, 0, 0, 1)' : 'rgba(0, 0, 0, 0.1)')};
  ${p => (p.isActive ? 'box-shadow: 0px 5px 15px 0 rgba(0,0,0,0.5);' : '')};
  border-radius: 4px;
  padding: 8px;
  min-width: 190px;

  &:hover {
    cursor: ${p => (p.isActive ? 'default' : 'pointer')};
  }
`;

export const AmountWrapper = styled('div')`
  font-weight: 600;
`;

export const IdWrapper = styled('div')`
  text-decoration: underline;
`;

export const LabelWrapper = styled('div')`
  font-weight: 600;
`;
