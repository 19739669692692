import styled from '@emotion/styled';
import { Handle, NodeProps, Position } from 'reactflow';

import useTxnLookupActions from '../../../TxnLookupContext';
import { AmountWrapper, IdWrapper, LabelWrapper } from './NodeLayout';
import { stringifyDate } from 'src/helpers/dateTimeHelpers';
import { isNil } from 'lodash';

type BasicNodeProps = {
  id: string;
  amount: number;
  color: string;
  createdAt: string;
  hasDeposit: boolean;
  type: string;
};

const RootNode = ({ data, isConnectable }: NodeProps<BasicNodeProps>) => {
  const { closeDetailsPanel } = useTxnLookupActions();

  const { id, amount, color, createdAt, hasDeposit, type } = data || {};

  return (
    <RootNodeWrapper color={color} onClick={closeDetailsPanel}>
      <LabelWrapper>{type} Txn Info</LabelWrapper>
      <IdWrapper>{id}</IdWrapper>
      <div>{stringifyDate(createdAt)}</div>
      {isNil(amount) ? null : <AmountWrapper>{amount}</AmountWrapper>}
      <Handle type="source" position={Position.Right} isConnectable={isConnectable} />
      {hasDeposit ? <Handle type="target" position={Position.Left} isConnectable={isConnectable} /> : null}
    </RootNodeWrapper>
  );
};

const RootNodeWrapper = styled('div')<{ color?: string }>`
  background-color: ${p => (p.color ? `${p.color}80` : '#b3ffb5')};
  border: 4px solid ${props => props.color || '#232323'};
  border-radius: 4px;
  min-width: 200px;
  padding: 8px;
`;

export default RootNode;
