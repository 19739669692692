import styled from '@emotion/styled';

import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined';
import Tooltip from '@mui/material/Tooltip';

import { stringifyDate } from 'src/helpers/dateTimeHelpers';

export default function PayoutTime({ payoutItem = {} }: { payoutItem: Record<string, any> }) {
  const { payoutTime, payoutSpeed = '' } = payoutItem;

  const payoutSpeedIcon =
    payoutSpeed === 'SAME_DAY' ? (
      <Tooltip title="The payout is under a faster payout speed" placement="left">
        <PayoutSpeedIcon fontSize="medium" sx={{ color: 'orange', marginTop: '-2px', marginBottom: '-2px' }} />
      </Tooltip>
    ) : (
      <span />
    );

  return (
    <PayoutTimeWrapper>
      <PayoutTimeValueWrapper>{stringifyDate(payoutTime)}</PayoutTimeValueWrapper>
      {payoutSpeedIcon}
    </PayoutTimeWrapper>
  );
}

const PayoutTimeWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

const PayoutTimeValueWrapper = styled('span')`
  vertical-align: middle;
  padding-right: 5px;
`;

const PayoutSpeedIcon = styled(OfflineBoltOutlinedIcon)``;
