import styled from '@emotion/styled';
import { get } from 'lodash';
import { useState } from 'react';

import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper/Paper';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';

interface FilterSectionProps {
  accountIdFromUrl: string;
  onSubmit: (accountId: string) => void;
}

const AccountFilters = ({ accountIdFromUrl = '', onSubmit }: FilterSectionProps) => {
  const [accountId, setAccountId] = useState(accountIdFromUrl);

  const disableActionButton = !accountId || accountId === accountIdFromUrl;

  return (
    <SearchWrapper>
      <TextField
        autoFocus
        fullWidth
        onChange={e => {
          const value = e.target.value || '';

          setAccountId(value);
        }}
        placeholder="Account or Business ID"
        required
        type="text"
        variant="standard"
        value={accountId}
        InputProps={{
          endAdornment:
            get(accountId, 'length', 0) > 0 ? (
              <ResetButton
                disabled={!accountId}
                onClick={() => setAccountId('')}
                size="small"
                sx={{ position: 'absolute', right: -2, top: -3 }}
              >
                <CloseIcon />
              </ResetButton>
            ) : null,
        }}
      />
      <SearchButton
        color="primary"
        disabled={disableActionButton}
        disableElevation
        onClick={() => onSubmit(accountId.trim())}
        type="submit"
      >
        <SearchIcon />
      </SearchButton>
    </SearchWrapper>
  );
};

const SearchButton = styled(Button)`
  min-width: 24px;
  min-height: 24px;
  padding: 8px;
`;

const ResetButton = styled(IconButton)``;

export const SearchWrapper = styled(Paper)`
  display: flex;
  align-items: center;
  padding: 4px 0 4px 12px;
  position: absolute;
  width: 420px;
`;

export default AccountFilters;
