import moment from 'moment';
import queryString from 'query-string';
import { get, isArray, isEmpty, isObject, reduce } from 'lodash';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';

// TODO Unify styled engine in @mui
// https://mui.com/guides/styled-engine/#how-to-switch-to-styled-components
import { styled } from '@mui/material/styles';

import AccountBalances from './components/accountBalances/AccountBalances';
import AccountFilters from './filters/AccountFilters';
import AccountInfo from './components/accountInfo/AccountInfo';
import AccountLedgers from './components/accountLedgers/AccountLedgers';
import AccountPayouts from './components/accountPayouts/AccountPayouts';
import FinancialInstrumentHistory from './components/finInstrumentHistory/FinancialInstrumentHistory';
import Spinner from 'src/components/spinner/Spinner';
import TabSwitcher from 'src/components/tabs/TabSwitcher';
import useAccountData from './hooks/useAccountData';
import useUrlParams from './hooks/useUrlHashParams';
import useUserData from 'src/context/UserContext';
import { AccountType } from 'src/constants/accountConstants';
import { ADDITIONAL_FILTERS_TYPE, FilterValuesType, REQUEST_ID_TYPE_KEY } from 'src/constants/filterConstants';
import { FiltersProvider } from 'src/context/FiltersContext';
import { NotFoundContainer, PageContent } from 'src/components/layouts/Layout';
import { toastError } from '../../helpers/toastHelpers';

const TabsConfig = {
  account: 0,
  balances: 1,
  ledgers: 2,
  payouts: 3,
  finInstruments: 4,
};

const DEFAULT_TAB_INDEX = 0;
const REQUEST_ID_TYPE_URL_PARAM = 'reqIdType';
const NO_PERMISSION_ERROR_MESSAGE = 'You do not have permission to request data by account ID';

const AccountContainer = (): ReactElement => {
  const [urlParams, updateUrlParams] = useUrlParams();
  const currentTabUrlParam = urlParams.get('tab');
  const reqIdTypeUrlParam = urlParams.get(REQUEST_ID_TYPE_URL_PARAM);
  const additionalFiltersParam = urlParams.get('additionalFilters');
  const accountId = urlParams.get('accountId') || '';

  const { userAbility } = useUserData();
  const noAccountIdRights = !userAbility.can('see', 'sensitiveInfo') && reqIdTypeUrlParam === AccountType.account;

  if (noAccountIdRights) {
    toastError(NO_PERMISSION_ERROR_MESSAGE);
  }

  const [filters, setFilters] = useState<FilterValuesType | null>({
    [REQUEST_ID_TYPE_KEY]: reqIdTypeUrlParam,
    [reqIdTypeUrlParam]: accountId,
  });

  const [additionalFilters, setAdditionalFilters] = useState<ADDITIONAL_FILTERS_TYPE>(
    () =>
      queryString.parse(additionalFiltersParam, {
        parseBooleans: true,
        arrayFormat: 'bracket',
      }) as ADDITIONAL_FILTERS_TYPE
  );

  const [currentTabIndex, setCurrentTabIndex] = useState<number>(
    get(TabsConfig, currentTabUrlParam, DEFAULT_TAB_INDEX)
  );

  const { accountData, getAccountData, isLoading } = useAccountData();

  const isLogicalAccount = get(accountData, 'type') === 'LOGICAL';

  const getFilledAdditionalFilters = useCallback((filters: any = {}) => {
    return reduce(
      filters,
      (memo: any, value, key) => {
        const isPlainValue = !isArray(value) && !isObject(value);

        if ((value && isPlainValue) || (value && !isEmpty(value)) || (value && moment(value).isValid())) {
          // eslint-disable-next-line no-param-reassign
          (memo = memo || {})[key] = value;
        }

        return memo;
      },
      null
    );
  }, []);

  const getFilterValues = (filters: any, additionalFilters?: any) => {
    const filledAdditionalFilters = additionalFilters
      ? queryString.stringify(getFilledAdditionalFilters(additionalFilters), { arrayFormat: 'bracket' })
      : null;

    return {
      ...getMainFilterValues(filters),
      additionalFilters: filledAdditionalFilters,
    };
  };

  const onAdditionalFiltersAction = useCallback(
    (updatedAdditionalFilters: any) => {
      setAdditionalFilters(updatedAdditionalFilters);

      const urlParamsToUpdate = getFilterValues(filters, updatedAdditionalFilters);

      updateUrlParams(urlParamsToUpdate);
    },
    [filters, getFilterValues, updateUrlParams]
  );

  const tabsConfig = useMemo(
    () => [
      { label: 'Account Info', tabContent: <AccountInfo data={accountData} />, type: 'account' },
      {
        label: 'Balances',
        tabContent: <AccountBalances accountData={accountData} />,
        type: 'balances',
      },
      {
        label: 'Account Ledgers',
        tabContent: (
          <AccountLedgers
            accountData={accountData}
            additionalFilters={additionalFilters}
            filters={filters}
            isLogicalAccount={isLogicalAccount}
            onLedgerFiltersAction={onAdditionalFiltersAction}
          />
        ),
        type: 'ledgers',
      },
      { label: 'Account Payouts', tabContent: <AccountPayouts filters={filters} />, type: 'payouts' },
      {
        label: 'Financial Instrument History',
        tabContent: <FinancialInstrumentHistory accountData={accountData} />,
        type: 'finInstruments',
      },
    ],
    [accountData, additionalFilters, isLogicalAccount, filters]
  );

  const getMainFilterValues = (filters: any) => {
    const requestIdType = get(filters, REQUEST_ID_TYPE_KEY);
    const isAccountRequestType = requestIdType === AccountType.account;

    return {
      [REQUEST_ID_TYPE_URL_PARAM]: isAccountRequestType ? AccountType.account : AccountType.business,
      accountId: get(filters, requestIdType),
      tab: currentTabIndex === DEFAULT_TAB_INDEX ? null : tabsConfig[currentTabIndex].type,
    };
  };

  useEffect(() => {
    if (accountId && !noAccountIdRights) {
      getAccountData(filters);
    }
  }, []);

  const onTabChange = useCallback(
    (index: number) => {
      setCurrentTabIndex(index);
      updateUrlParams({
        tab: index === DEFAULT_TAB_INDEX ? null : tabsConfig[index].type,
      });
    },
    [tabsConfig, updateUrlParams]
  );

  const getTabSwitcher = () => {
    const id = get(filters, filters?.requestIdType || '');

    if (!id) {
      return '';
    }

    if (isEmpty(accountData)) {
      return <NotFoundContainer>No data found</NotFoundContainer>;
    }

    return <TabSwitcher currentTabIndex={currentTabIndex} onTabChange={onTabChange} tabsConfig={tabsConfig} />;
  };

  const tabSwitcher = getTabSwitcher();

  const onMainFiltersSubmit = useCallback(
    (accountId: string) => {
      const requestIdType = accountId.substring(0, 8) === 'urn:acc:' ? AccountType.account : AccountType.business;

      if (noAccountIdRights) {
        toastError(NO_PERMISSION_ERROR_MESSAGE);
        return;
      }

      const filtersToUpdate = { [requestIdType]: accountId, [REQUEST_ID_TYPE_KEY]: requestIdType };

      updateUrlParams({
        ...getFilterValues(filtersToUpdate),
        tab: null,
      });

      setFilters(filtersToUpdate);
      getAccountData(filtersToUpdate);
    },
    [getAccountData, getFilterValues, updateUrlParams]
  );

  const accountInfoContent = isLoading ? (
    <SpinnerContainer>
      <Spinner />
    </SpinnerContainer>
  ) : (
    tabSwitcher
  );

  return (
    <PageContent>
      <FiltersProvider>
        <AccountFilters accountIdFromUrl={accountId} onSubmit={onMainFiltersSubmit} />
        <AccountData>{accountInfoContent}</AccountData>
      </FiltersProvider>
    </PageContent>
  );
};

const AccountData = styled('section')`
  width: 100%;
`;

const SpinnerContainer = styled('div')`
  display: flex;
  justify-content: center;
  padding-top: 80px;
  width: 100%;
`;

export default AccountContainer;
