import { ReactElement } from 'react';
import styled from '@emotion/styled';
import { get } from 'lodash';
import { useQuery } from '@apollo/client';

import PriceProfileInfo from './components/PriceProfileInfo';
import PriceProfiles from './components/PriceProfiles';
import useUserData from 'src/context/UserContext';
import { Account } from 'src/constants/accountConstants';
import { CURRENT_PRICE_PROFILE } from 'src/services/apollo/queries/pricingQueries';
import { PricingProfile } from './constants/pricingProfileConstants';

export default function PriceProfileManagement({ data }: { data: Account }): ReactElement {
  const { userAbility } = useUserData();

  const userCanManagePricingProfiles = userAbility.can('manage', 'PRICING_PROFILES');

  const { data: currentPriceProfileResponse, loading: currentPriceProfileIsLoading } = useQuery(CURRENT_PRICE_PROFILE, {
    variables: { businessId: data.ownerId },
  });

  const currentPriceProfile = get(currentPriceProfileResponse, 'currentPriceProfile', {}) as PricingProfile;
  const currentPriceProfileId = get(currentPriceProfileResponse, 'currentPriceProfile.priceProfileId', '');

  return (
    <PriceProfileManagementWrapper>
      <PriceProfileInfo
        priceProfileData={currentPriceProfile}
        priceProfileIsLoading={currentPriceProfileIsLoading}
        isCurrent
      />
      {userCanManagePricingProfiles ? (
        <PriceProfiles
          accountData={data}
          currentPriceProfileId={currentPriceProfileId}
          currentPriceProfile={currentPriceProfile}
        />
      ) : null}
    </PriceProfileManagementWrapper>
  );
}

const PriceProfileManagementWrapper = styled('div')`
  display: flex;
  padding: 8px 0;
  width: 100%;
`;
