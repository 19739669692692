import { ReactElement } from 'react';
import { get } from 'lodash';
import { useQuery } from '@apollo/client';

import BankAccounts from './components/BankAccounts';
import DebitCards from './components/DebitCards';
import Spinner, { SpinnerContainer } from 'src/components/spinner/Spinner';
import { Account } from 'src/constants/accountConstants';
import { FINANCIAL_INSTRUMENTS_QUERY } from 'src/services/apollo/queries/accountQueries';

interface FinInstrumentHistoryProps {
  accountData: Account | null;
}

export default function FinancialInstrumentHistory({ accountData }: FinInstrumentHistoryProps): ReactElement {
  const businessId = get(accountData, 'ownerId', '') as string;

  const { loading: isLoading, data: response } = useQuery(FINANCIAL_INSTRUMENTS_QUERY, {
    variables: {
      businessId,
    },
  });

  const bankAccountHistoryItems = get(response, 'financialInstruments.bankHistory', []);
  const cardData = get(response, 'financialInstruments.cards', []);

  return isLoading ? (
    <SpinnerContainer>
      <Spinner />
    </SpinnerContainer>
  ) : (
    <>
      <BankAccounts bankAccountHistoryItems={bankAccountHistoryItems} />
      <DebitCards cardData={cardData} />
    </>
  );
}
