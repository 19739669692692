import { AccountDataProps } from 'src/constants/accountConstants';

export interface TxnAccountInfo extends AccountDataProps {
  name: string;
  id: string;
  status: string;
}

export interface TxnPayout {
  id: string;
  createdAt: string;
  currency: string;
  fundingAgentId: string;
  ledgerCount: number;
  netLedgerAmount: number;
  payoutAmount: number;
  payoutFee: number;
  payoutTime: string;
  subTotalAmount: number;
  transferId: string;
  transferRequestId: string;
  transferStatus: string;
  updatedAt: string;
}

export interface TxnLedger {
  id: string;
  amount: number;
  ledgeredAt: string;
  type: string;
  refType: string;
  refId: string;
  currency: string;
  notes: string;
  payout: TxnPayout;
  payoutId: string;
  availableBalance: number;
  actualBalance: number;
  sourceAccount: string;
  scheduledAt: string;
  balancedAt: string;
  createdAt: string;
  updatedAt: string;
}

interface Deposit {
  acquirer: string;
  amount: string;
  authorizationCode: string;
  businessType: string;
  cardNo: string;
  currency: string;
  depositedAt: string;
  fundingId: string;
  id: string;
  mid: string;
  processor: string;
  storeId: string;
}

export interface TxnInfo {
  id: string;
  action: string;
  amount: number;
  createdAt: string;
  deposit?: Deposit;
  isParent?: boolean;
  isSettled: string;
  ledgeredAt: string;
  status: string;
  ledgers?: TxnLedger[];
}

export interface TxnHierarchy {
  baseTxn: TxnInfo;
  parentTxn?: TxnInfo;
  childTxns?: TxnInfo[];
}

export interface FullTxnData {
  accountData: TxnAccountInfo;
  transactionData: TxnHierarchy;
}

export const NodeColorMapper = {
  default: '#b3ffb5',
  deposit: '#fff79e',
  payout: '#FF5733',
  ledger: '#fdad49',
};
