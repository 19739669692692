import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import VisibilityIcon from '@mui/icons-material/Visibility';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface ViewDetailsProps {
  href: string;
}

const DetailsIcon = styled(VisibilityIcon)`
  font-size: 1.75rem;
`;

export const ViewDetails = ({ href }: ViewDetailsProps) => {
  return (
    <Tooltip title="View details" placement="top">
      <IconButton component={Link} to={href}>
        <DetailsIcon />
      </IconButton>
    </Tooltip>
  );
};
