import { ReactElement } from 'react';

import useUserData from 'src/context/UserContext';
import { Navigation, StyledNavLink } from 'src/components/layouts/Layout';

const MainMenu = (): ReactElement => {
  const { userAbility, user } = useUserData();

  // Change this to something meaningful
  const userCanAccessPayfacTools = user.role === 'AdminWrite';

  const userCanAccessReportsMenu =
    userAbility.can('see', 'PARTNERS_COMMISSION_REPORT') || userAbility.can('see', 'SALES_COMPENSATION_REPORT');

  return (
    <Navigation>
      <StyledNavLink to="/account">Account Lookup</StyledNavLink>
      <StyledNavLink to="/txn-lookup">Transaction Lookup</StyledNavLink>
      {userCanAccessPayfacTools ? <StyledNavLink to="/payfac-tools">Payfac Tools</StyledNavLink> : null}
      {userCanAccessReportsMenu ? <StyledNavLink to="/aggregated-reports">Aggregated Reports</StyledNavLink> : null}
      <StyledNavLink to="/reporting-service">Reporting Service</StyledNavLink>
    </Navigation>
  );
};

export default MainMenu;
