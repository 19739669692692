import { ReactElement, useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { get, sortBy } from 'lodash';
import { useApolloClient } from '@apollo/client';

import Button from '@mui/material/Button';

import DropdownWithSearch from 'src/components/common/Dropdown/DropdownWithSearch';
import PriceProfileChangeConfirmation from './PriceProfileChangeConfirmation';
import { Account } from 'src/constants/accountConstants';
import { ASSIGN_PRICE_PROFILE_MUTATION } from 'src/services/apollo/mutations/priceProfileMutation';
import { DropdownItemType } from 'src/constants/commonConstants';
import { CURRENT_PRICE_PROFILE, PRICING_PROFILES_BY_NAME_QUERY } from 'src/services/apollo/queries/pricingQueries';
import { PricingProfile, PricingProfileNameItem } from '../constants/pricingProfileConstants';
import { toastAutoCloseError, toastSuccess } from 'src/helpers/toastHelpers';

export default function PriceProfiles({
  currentPriceProfile,
  currentPriceProfileId,
  accountData,
}: {
  accountData: Account;
  currentPriceProfileId: string;
  currentPriceProfile: PricingProfile;
}): ReactElement {
  const [selectedPriceProfileId, setSelectedPriceProfileId] = useState('');
  const [priceProfilesOptions, setPriceProfilesOptions] = useState<DropdownItemType[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const apolloClient = useApolloClient();

  const handleClose = useCallback(() => {
    setIsConfirmationDialogOpen(false);
  }, [setIsConfirmationDialogOpen]);

  const { ownerId: businessId, name: businessName = '' } = accountData;

  const searchFunction = useCallback(async (searchString: string) => {
    try {
      if (!searchString) {
        return;
      }

      setIsLoading(true);

      const { data } = await apolloClient.query({
        query: PRICING_PROFILES_BY_NAME_QUERY,
        variables: { name: searchString },
      });

      if (data) {
        const rawPricingProfiles = sortBy(
          get(data, 'pricingProfilesByName', []),
          (profile: PricingProfileNameItem) => +profile.id
        );

        const pricingProfiles = rawPricingProfiles.map((profile: PricingProfileNameItem) => ({
          label: `${profile.id} - ${profile.name}`,
          value: profile.id,
        }));

        const availablePriceProfileOptions = [{ label: 'None', value: '' }, ...pricingProfiles];

        setPriceProfilesOptions(availablePriceProfileOptions);
      }
    } catch (e) {
      setPriceProfilesOptions(null);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const onPriceProfileConfirm = useCallback(async () => {
    try {
      setIsLoading(true);

      const { data } = await apolloClient.mutate({
        mutation: ASSIGN_PRICE_PROFILE_MUTATION,
        variables: { businessId, priceProfileId: selectedPriceProfileId, oldPriceProfileId: currentPriceProfileId },
        refetchQueries: [
          {
            query: CURRENT_PRICE_PROFILE,
            variables: { businessId },
          },
        ],
        awaitRefetchQueries: true,
      });

      const newPriceProfileId = data?.assignPriceProfile?.id;

      if (newPriceProfileId === currentPriceProfileId) {
        toastAutoCloseError('Pricing profile was not changed');
        return;
      }

      setIsConfirmationDialogOpen(false);
      setIsLoading(false);

      if (!newPriceProfileId) {
        toastAutoCloseError('Error updating the pricing profile');
        return;
      }

      setSelectedPriceProfileId('');
      setPriceProfilesOptions(null);

      toastSuccess('Price profile successfully updated. You may view the updated pricing in the pricing tab', {
        autoClose: 5000,
      });
    } catch (e) {
      // eslint-disable-no-empty
    } finally {
      setIsLoading(false);
    }
  }, [businessId, selectedPriceProfileId, currentPriceProfileId]);

  const onPriceProfileSubmit = () => {
    setIsConfirmationDialogOpen(true);
  };

  const submitButtonDisabled = !selectedPriceProfileId || selectedPriceProfileId === currentPriceProfileId || isLoading;

  return (
    <PriceProfilesWrapper>
      {isConfirmationDialogOpen ? (
        <PriceProfileChangeConfirmation
          businessId={businessId}
          businessLabel={businessName}
          currentPriceProfile={currentPriceProfile}
          handleClose={handleClose}
          handleSubmit={onPriceProfileConfirm}
          isLoading={isLoading}
          selectedPriceProfileId={selectedPriceProfileId}
        />
      ) : null}
      <PriceProfilesHeader>Price Profile Management</PriceProfilesHeader>
      <DropdownWithSearch
        disabledOptions={[selectedPriceProfileId, currentPriceProfileId]}
        label="Change price profile to:"
        loading={isLoading}
        onSelectHandler={setSelectedPriceProfileId}
        options={priceProfilesOptions}
        searchPlaceholder="Search by name or ID"
        searchFunction={searchFunction}
      />
      <Button disabled={submitButtonDisabled} onClick={onPriceProfileSubmit} variant="outlined">
        Submit
      </Button>
    </PriceProfilesWrapper>
  );
}

const PriceProfilesWrapper = styled('div')`
  border-left: 1px solid #ababab;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  padding-left: 20px;
  width: 100%;
`;

const PriceProfilesHeader = styled('div')`
  font-weight: 600;
  margin-bottom: 12px;
  text-transform: uppercase;
`;
