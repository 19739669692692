import { ReactElement, useState } from 'react';
import { isEmpty, orderBy } from 'lodash';
import { styled } from '@mui/material';

import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Tooltip from '@mui/material/Tooltip';

import InfoTable from 'src/components/infoTable/InfoTable';
import Table from 'src/components/table/Table';
import {
  bankAccountHistoryColumnsConfig,
  MAXIMUM_HISTORY_ITEMS,
} from '../constants/financialInstrumentHistoryConstants';
import { SectionHeader } from './common/Layout';

interface BankAccountsProps {
  bankAccountHistoryItems: {
    routingNumber: string;
    accountNumberLast4: string;
    bankName: string;
    accountType: string;
    status: string;
    createdAt: string;
    accountValidationResult: string;
    returnCode: string;
  }[];
}

export default function BankAccounts({ bankAccountHistoryItems = [] }: BankAccountsProps): ReactElement {
  const sortedBankAccountHistoryItems = orderBy(bankAccountHistoryItems, ['createdAt'], ['desc']);

  return (
    <BankAccountsWrapper>
      <SectionHeader>Bank Account</SectionHeader>
      <TableContent>
        <Table
          columnsConfig={bankAccountHistoryColumnsConfig}
          rows={sortedBankAccountHistoryItems}
          rowsPerPage={MAXIMUM_HISTORY_ITEMS}
        />

        {isEmpty(sortedBankAccountHistoryItems) ? null : <Footnotes />}
      </TableContent>
    </BankAccountsWrapper>
  );
}

const Footnotes = () => {
  const [open, setOpen] = useState(false);

  const handleChange = () => {
    setOpen(prev => !prev);
  };

  const toggleFootnotes = open ? (
    <ToggleFootnotesButton onClick={handleChange}>
      <KeyboardDoubleArrowRightIcon />
    </ToggleFootnotesButton>
  ) : (
    <Tooltip title="Detailed description of some table parameters" placement="left" onClick={handleChange}>
      <ToggleFootnotesButton>
        <KeyboardDoubleArrowLeftIcon />
      </ToggleFootnotesButton>
    </Tooltip>
  );

  return (
    <FootnotesWrapper>
      {toggleFootnotes}

      <Collapse in={open} orientation="horizontal" unmountOnExit>
        <FootnoteContainer>
          <SectionDescription>
            Below are the four possible values (and the explanations) under the{' '}
            <strong>Account Validation Result.</strong>
          </SectionDescription>

          <InfoTable
            data={[
              {
                label: 'bav_debit_failed',
                value: 'it means the bank account validation with a debit attempt failed.',
              },
              {
                label: 'bav_credit_failed',
                value: 'it means the bank account validation with a credit attempt failed.',
              },
              {
                label: 'payout_failed',
                value:
                  'the payout is disabled because the payout attempt failed (might due to the merchant changing\n' +
                  'the bank account).',
              },
              {
                label: 'noc_update',
                value:
                  'there has been a notification of change (NOC) issued by the bank indicating there is an issue\n' +
                  'with the underlying account’s information in the ACH network.',
              },
            ]}
          />

          <SectionDescription>
            <strong>RETURN REASON</strong> explains why the bank account validation failed. Most return reasons are
            self-explanatory (e.g., insufficient fund, account not found), but below are explanations for the less
            straightforward ones.
          </SectionDescription>
          <InfoTable
            data={[
              {
                label: 'RDFI_NOT_QUALIFIED',
                value:
                  // eslint-disable-next-line max-len
                  'RDFI stands for Receiving Depository Financial Institution. This return code means that the underlying financial institution is not qualified to participate the ACH network.',
              },
              {
                label: 'PAYMENT_STOPPED',
                value:
                  // eslint-disable-next-line max-len
                  'this is a general category to indicate that the transaction stopped from being processed (usually due to error such as incorrect entry).',
              },
              {
                label: 'NON_TRANSACTION_ACCOUNT',
                value:
                  // eslint-disable-next-line max-len
                  'as indicated by the reason, the account does not allow ACH payments. Examples of non-transaction account include savings deposits, money market deposit accounts.',
              },
              {
                label: 'RETURNED_PER_ODFI_REQUEST',
                value:
                  // eslint-disable-next-line max-len
                  'ODFI stands for Originating Depository Financial Institutions. This return reason means the return is requested by the originating party.',
              },
              {
                label: 'ODFI_VALIDACTION_FAILED',
                value: 'In this case, the ODFI is revoking the authorization previously granted on the transfer.',
              },
            ]}
          />
        </FootnoteContainer>
      </Collapse>
    </FootnotesWrapper>
  );
};

const BankAccountsWrapper = styled('div')`
  margin-bottom: 36px;
`;

const TableContent = styled('div')`
  display: flex;
  width: 100%;
`;

const SectionDescription = styled('p')`
  margin-bottom: 6px;
`;

const ToggleFootnotesButton = styled(Button)`
  min-width: 32px;
`;

const FootnotesWrapper = styled('div')`
  display: flex;
`;

const FootnoteContainer = styled('div')`
  padding: 8px 12px 4px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 0.8rem;
  width: 600px;
`;
