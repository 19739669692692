import { ReactElement, useEffect, useMemo } from 'react';
import { filter, get, includes, isEmpty, isEqual, toPairs } from 'lodash';
import { joiResolver } from '@hookform/resolvers/joi';
import { styled } from '@mui/material/styles';
import { useForm } from 'react-hook-form';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper/Paper';
import Stack from '@mui/material/Stack';

import DateTimeRangePickerField from 'src/components/form/fields/DateTimeRangePickerField';
import DropdownField from 'src/components/form/fields/DropdownField';
import MultipleChipsField from 'src/components/form/fields/MultipleChipsField';
import SwitchField from 'src/components/form/fields/SwitchField';
import useFiltersData from 'src/context/FiltersContext';
import {
  ADDITIONAL_FILTERS_TYPE,
  LedgerFilterDefs,
  ledgerFiltersDefaultValues,
  OrderDirOptions,
  TimeTypeDefs,
} from 'src/constants/filterConstants';
import { FormActions, PrimaryButton } from 'src/components/form/FormLayout';
import { getOptions } from 'src/helpers/commonHelpers';
import { ledgerFiltersSchema } from './../components/accountLedgers/validation/ledgerFiltersSchema';

interface FilterSectionProps {
  isLogicalAccount: boolean;
  onAction: (values: ADDITIONAL_FILTERS_TYPE) => void;
  filters: ADDITIONAL_FILTERS_TYPE;
}

export default function LedgerFilters(props: FilterSectionProps): ReactElement {
  const { filters, isLogicalAccount, onAction } = props;
  const { filterActionPayload } = useFiltersData();

  const ledgerFiltersInitialValues = useMemo(
    () => ({
      ...ledgerFiltersDefaultValues,
      ...filters,
    }),
    [filters]
  );

  const {
    control,
    formState: { isDirty, errors },
    getValues,
    reset,
    setValue,
  } = useForm({
    defaultValues: useMemo(() => ledgerFiltersInitialValues, [ledgerFiltersInitialValues]),
    resolver: joiResolver(ledgerFiltersSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    const values = getValues();

    if (isEmpty(filterActionPayload)) {
      return;
    }

    // filterActionPayload supposed to have only one filter key-value payload
    const [key, value] = toPairs(filterActionPayload)[0];

    if (!Object.values(LedgerFilterDefs).includes(key as LedgerFilterDefs)) {
      return;
    }

    const existingValues = get(values, key, []);

    if (!includes(existingValues, value)) {
      setValue(key as LedgerFilterDefs, [...existingValues, value], {
        shouldDirty: true,
      });
    }
  }, [filterActionPayload]);

  useEffect(() => {
    reset(ledgerFiltersInitialValues);

    return () => {
      reset(ledgerFiltersDefaultValues);
    };
  }, [ledgerFiltersInitialValues, reset]);

  const currentFilterValues = getValues();

  const filtersAreChanged = !isEqual(ledgerFiltersInitialValues, currentFilterValues);
  const filtersAreDifferentFromDefaultValues = !isEqual(ledgerFiltersDefaultValues, currentFilterValues);

  const onFiltersSubmit = () => {
    onAction(getValues());
  };

  const onFiltersReset = () => {
    reset(ledgerFiltersDefaultValues);
    onAction(ledgerFiltersDefaultValues);
  };

  const timeTypeOptions = useMemo(
    () =>
      getOptions(
        isLogicalAccount ? TimeTypeDefs : filter(TimeTypeDefs, type => type !== TimeTypeDefs.BALANCED_AT),
        true
      ),
    [isLogicalAccount]
  );

  return (
    <LedgerFiltersWrapper>
      <SwitchBlock>
        <SwitchField
          control={control}
          key={LedgerFilterDefs.unbalancedLedgers}
          label="Unbalanced Ledgers"
          name={LedgerFilterDefs.unbalancedLedgers}
        />
        <SwitchField
          control={control}
          key={LedgerFilterDefs.unpaidLedgers}
          label="Unpaid Ledgers"
          name={LedgerFilterDefs.unpaidLedgers}
        />
      </SwitchBlock>

      <MultipleChipsField
        control={control}
        key={LedgerFilterDefs.types}
        label="Ledger Types"
        name={LedgerFilterDefs.types}
      />
      <MultipleChipsField
        control={control}
        key={LedgerFilterDefs.refTypes}
        label="Ledger Ref Types"
        name={LedgerFilterDefs.refTypes}
      />
      <MultipleChipsField
        control={control}
        key={LedgerFilterDefs.excludeTypes}
        label="Ledger Exclude Types"
        name={LedgerFilterDefs.excludeTypes}
      />
      <MultipleChipsField
        control={control}
        key={LedgerFilterDefs.excludeRefTypes}
        label="Ledger Exclude Ref Types"
        name={LedgerFilterDefs.excludeRefTypes}
      />
      <MultipleChipsField
        control={control}
        key={LedgerFilterDefs.payoutIds}
        label="Payout IDs"
        name={LedgerFilterDefs.payoutIds}
      />
      <MultipleChipsField
        control={control}
        key={LedgerFilterDefs.refIds}
        label="Reference IDs"
        name={LedgerFilterDefs.refIds}
      />
      <TimeRangeSelectorContainer>
        <Stack spacing="14px">
          <DateTimeRangePickerField
            control={control}
            dateFromLabel="Start Time"
            dateToLabel="End Time"
            dateFromName={LedgerFilterDefs.startTime}
            dateToName={LedgerFilterDefs.endTime}
          />
          <Stack spacing={2} direction="row">
            <DropdownField
              control={control}
              fullWidth
              items={timeTypeOptions}
              key={LedgerFilterDefs.timeType}
              label="Time Type"
              name={LedgerFilterDefs.timeType}
            />
            <DropdownField
              control={control}
              fullWidth
              items={OrderDirOptions}
              key={LedgerFilterDefs.orderDir}
              label="Order Direction"
              name={LedgerFilterDefs.orderDir}
            />
          </Stack>
        </Stack>
      </TimeRangeSelectorContainer>

      <FormActions>
        <PrimaryButton
          disabled={!filtersAreChanged || !isEmpty(errors)}
          onClick={onFiltersSubmit}
          variant="contained"
          color="primary"
        >
          Filter Ledgers
        </PrimaryButton>
        <Button disabled={!isDirty && !filtersAreDifferentFromDefaultValues} onClick={onFiltersReset}>
          Reset
        </Button>
      </FormActions>
    </LedgerFiltersWrapper>
  );
}

export const LedgerFiltersWrapper = styled(Paper)`
  padding: 16px 12px 8px;
`;

const TimeRangeSelectorContainer = styled('div')`
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: 8px;
  padding: 12px 8px;
`;

TimeRangeSelectorContainer.displayName = 'TimeRangeSelectorContainer';

const SwitchBlock = styled('div')`
  margin-bottom: 16px;
`;

SwitchBlock.displayName = 'SwitchBlock';
