import React from 'react';
import styled from '@emotion/styled';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

type ConfirmationDialogProps = {
  title: string;
  description: string;
  isOpen: boolean;
  isLoading?: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  confirmLabel?: string;
  cancelLabel?: string;
};

const ConfirmationDialog = ({
  title,
  description,
  isOpen,
  isLoading = false,
  handleClose,
  handleConfirm,
  confirmLabel = 'Confirm',
  cancelLabel = 'Cancel',
}: ConfirmationDialogProps) => {
  return (
    <Dialog maxWidth="sm" open={isOpen} onClose={handleClose}>
      {/* Loading Backdrop */}
      <Backdrop sx={{ color: '#ccc', zIndex: 100 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* Dialog Content */}
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{ my: 3 }}>
          <HighlightedText>{description}</HighlightedText>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isLoading}>
          {cancelLabel}
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          variant="contained"
          disabled={isLoading}
        >
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const HighlightedText = styled('span')`
  color: #3b3b3b;
  font-weight: 600;
`;

export default ConfirmationDialog;
