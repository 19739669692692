import { Handle, NodeProps, Position } from 'reactflow';

import useTxnLookupActions, { DETAILS_PANEL_STATE } from '../../../TxnLookupContext';
import { AmountWrapper, IdWrapper, LabelWrapper, NodeWrapper } from './NodeLayout';
import { NodeColorMapper } from '../../../constants/txnConstants';
import { stringifyDate } from 'src/helpers/dateTimeHelpers';

type DepositNodeProps = {
  id: string;
  shortId: string;
  amount: number;
  depositedAt: string;
};

const DepositNode = ({ data, isConnectable }: NodeProps<DepositNodeProps>) => {
  const { onDepositClick, detailsPanelState } = useTxnLookupActions();

  const { itemId } = detailsPanelState;

  const isActive = detailsPanelState.state === DETAILS_PANEL_STATE.deposit && itemId === data.id;

  return (
    <NodeWrapper color={NodeColorMapper.deposit} isActive={isActive} onClick={() => onDepositClick(data.id)}>
      <LabelWrapper>Deposit Info</LabelWrapper>
      <IdWrapper>{data.shortId}</IdWrapper>
      <div>{stringifyDate(data.depositedAt)}</div>
      <AmountWrapper>{data.amount}</AmountWrapper>
      <Handle type="source" position={Position.Right} isConnectable={isConnectable} />
    </NodeWrapper>
  );
};

export default DepositNode;
