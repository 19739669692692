import { Handle, NodeProps, Position } from 'reactflow';

import useTxnLookupActions, { DETAILS_PANEL_STATE } from '../../../TxnLookupContext';
import { AmountWrapper, IdWrapper, LabelWrapper, NodeWrapper } from './NodeLayout';
import { stringifyDate } from 'src/helpers/dateTimeHelpers';
import { NodeColorMapper } from 'src/containers/txnLookup/constants/txnConstants';

type PayoutNodeProps = {
  id: string;
  shortId: string;
  color: string;
  amount: number;
  payoutTime: string;
};

const PayoutNode = ({ data, isConnectable }: NodeProps<PayoutNodeProps>) => {
  const { onPayoutClick, detailsPanelState } = useTxnLookupActions();

  const { itemId } = detailsPanelState;

  const isActive = detailsPanelState.state === DETAILS_PANEL_STATE.payout && itemId === data.id;

  return (
    <NodeWrapper
      color={data.color || NodeColorMapper.payout}
      isActive={isActive}
      onClick={() => onPayoutClick(data.id)}
    >
      <LabelWrapper>Payout Info</LabelWrapper>
      <IdWrapper>{data.shortId}</IdWrapper>
      <div>{stringifyDate(data.payoutTime)}</div>
      <AmountWrapper>{data.amount}</AmountWrapper>
      <Handle type="target" position={Position.Left} isConnectable={isConnectable} />
    </NodeWrapper>
  );
};

export default PayoutNode;
