import { get, isEmpty, isObject, reduce, sortBy } from 'lodash';

import { TxnInfo, TxnLedger, TxnPayout } from '../constants/txnConstants';

export enum TXN_TYPES {
  SALES = 'SALES',
  AUTHORIZATION = 'AUTHORIZATION',
  DECLINE = 'DECLINE',
  REFUND = 'REFUND',
  VOID = 'VOID',
}

export const TXN_TYPE_MAPPER = [
  {
    type: TXN_TYPES.SALES,
    mappings: [
      { action: 'S', status: 'C' },
      { action: 'S', status: 'P' },
      { action: 'S', status: 'R' },
    ],
  },
  {
    type: TXN_TYPES.AUTHORIZATION,
    mappings: [{ action: 'A', status: 'C' }],
  },
  {
    type: TXN_TYPES.DECLINE,
    mappings: [{ action: 'S', status: 'D' }],
  },
  {
    type: TXN_TYPES.REFUND,
    mappings: [{ action: 'R', status: 'R' }],
  },
  {
    type: TXN_TYPES.VOID,
    mappings: [
      { action: null, status: 'V' }, // 'null' for any action
      { action: 'V', status: null }, // 'null' for any status
    ],
  },
] as const;


const TXN_DEFAULT_NODE_COLOR = '#b3ffb5';

const TXN_COLOR_MAPPER = {
  [TXN_TYPES.SALES]: '#008000', // green
  [TXN_TYPES.AUTHORIZATION]: '#0000FF', // blue
  [TXN_TYPES.DECLINE]: '#8B0000', // darkred
  [TXN_TYPES.REFUND]: '#FF5957', // #ff5957
  [TXN_TYPES.VOID]: '#595959', // #595959
};

const yMapper = {
  1: [300],
  2: [200, 400],
  3: [150, 300, 450],
  4: [100, 250, 400, 550],
  5: [75, 200, 325, 450, 575],
  6: [60, 180, 300, 420, 540, 660],
  7: [60, 180, 300, 420, 540, 660, 780],
  8: [60, 180, 300, 420, 540, 660, 780, 900],
  9: [60, 180, 300, 420, 540, 660, 780, 900, 1020],
  10: [60, 180, 300, 420, 540, 660, 780, 900, 1020, 1140],
};

export const getTransactionType = (transaction?: TxnInfo) => {
  if (!transaction) {
    return '';
  }

  const { status, action } = transaction || {};

  const typeItem = TXN_TYPE_MAPPER.find(({ mappings }) =>
    mappings.some(
      mapping =>
        (!mapping.action && mapping.status === status) ||
        (!mapping.status && mapping.action === action) ||
        (mapping.action === action && mapping.status === status)
    )
  );

  return typeItem?.type || '';
};

export const getTransactionColor = (transactionType: string) => {
  return get(TXN_COLOR_MAPPER, transactionType, TXN_DEFAULT_NODE_COLOR);
};

const initDepositNode = (txnData: TxnInfo) => {
  const { deposit } = txnData;

  if (!deposit?.id) {
    return { nodes: [] as any[], edges: [] as any[] };
  }

  return {
    nodes: [
      {
        id: 'deposit',
        data: {
          amount: deposit.amount,
          depositedAt: deposit.depositedAt,
          shortId: (deposit.id || '').substring(0, 16),
          id: deposit.id,
        },
        position: { x: 220, y: 300 },
        type: 'depositNode',
      },
    ],
    edges: [
      {
        id: 'deposit-root',
        source: 'deposit',
        target: 'root',
      },
    ],
  };
};

export const getPayoutNode = (ledgers: TxnLedger[] = [], payout: TxnPayout | null, index: number) => {
  if (!payout || !isObject(payout) || !payout.id) {
    return null;
  }

  const payoutId = payout.id;
  const prevPayoutId = index > 0 && !isEmpty(ledgers) ? get(ledgers, `${index - 1}.payoutId`) : '';

  if (prevPayoutId === payoutId) {
    return null;
  }

  const ledgerCount = ledgers.length;
  const numberOfLedgersWithThisPayout = ledgers.filter(ledger => ledger.payoutId === payoutId).length;

  const yStartPosition = get(yMapper, `${ledgerCount}.${index}`, yMapper[1][0]);
  const yStep = ledgerCount > 1 ? get(yMapper, ledgerCount)[1] - get(yMapper, ledgerCount)[0] : yMapper[1][0];
  const yPosition =
    numberOfLedgersWithThisPayout > 1
      ? yStartPosition + (yStep * (numberOfLedgersWithThisPayout - 1)) / 2
      : yStartPosition;

  return {
    id: `payout${index}`,
    data: {
      id: payoutId,
      shortId: (payoutId || '').substring(0, 16),
      amount: payout.payoutAmount,
      payoutTime: payout.payoutTime,
    },
    position: { x: 1050, y: yPosition },
    type: 'payoutNode',
  };
};

const getUpdatedNodes = ({
  nodes,
  ledgers,
  index,
  ledger,
}: {
  nodes: any[];
  ledgers: TxnLedger[];
  index: number;
  ledger: TxnLedger;
}) => {
  const payoutNode = getPayoutNode(ledgers, ledger.payout, index);

  return [
    ...nodes,
    {
      id: `ledger${index}`,
      data: {
        id: ledger.id,
        amount: ledger.amount,
        ledgeredAt: ledger.ledgeredAt,
        shortId: (ledger.id || '').substring(0, 16),
        type: ledger.type,
      },
      position: { x: 800, y: get(yMapper, `${ledgers.length}.${index}`, 300) },
      type: 'ledgerNode',
    },
    ...(payoutNode ? [payoutNode] : []),
  ];
};

const getUpdatedEdges = ({
  nodes,
  edges,
  index,
  payoutId,
}: {
  nodes: any[];
  edges: any[];
  index: number;
  payoutId: string | null;
}) => {
  const getLedgerToPayoutEdge = (payoutId: string | null, index: number) => {
    if (!payoutId) {
      return [];
    }

    const payoutNode = nodes.find(node => node.type === 'payoutNode' && node.data.id === payoutId);

    return [
      {
        id: `ledger${index}-payout`,
        source: `ledger${index}`,
        target: payoutNode ? payoutNode.id : `payout${index}`,
      },
    ];
  };

  return [
    ...edges,
    { id: `root-ledger${index}`, source: 'root', target: `ledger${index}` },
    ...getLedgerToPayoutEdge(payoutId, index),
  ];
};

const initializeConnectedNodes = (txnData: TxnInfo) => {
  const ledgers = (txnData?.ledgers as TxnLedger[]) || [];

  const nodesState = initDepositNode(txnData);

  if (isEmpty(ledgers)) {
    return nodesState;
  }

  const sortedLedgers = sortBy(ledgers, ['payoutId']);

  return reduce(
    sortedLedgers,
    (acc, ledger, index) => {
      const { nodes, edges } = acc;

      return {
        nodes: getUpdatedNodes({ nodes, ledgers: sortedLedgers, index, ledger }),
        edges: getUpdatedEdges({ nodes, edges, index, payoutId: ledger.payoutId }),
      };
    },
    nodesState
  );
};

export const initializeNodes = (txnData: TxnInfo) => {
  const txnType = getTransactionType(txnData);
  const transactionColor = getTransactionColor(txnType);

  const { nodes, edges } = initializeConnectedNodes(txnData);

  return {
    nodes: [
      {
        id: 'root',
        data: {
          id: (txnData.id || '').substring(0, 8),
          amount: txnData.amount,
          color: transactionColor,
          createdAt: txnData.createdAt,
          hasDeposit: !!txnData.deposit?.id,
          type: txnType,
        },
        position: { x: 500, y: 298 }, // 300-2, compensating for border width
        type: 'rootNode',
      },
      ...nodes,
    ],
    edges,
  };
};
