import { ReactElement } from 'react';
import { orderBy } from 'lodash';
import { styled } from '@mui/material';

import Table from 'src/components/table/Table';
import { debitCardsColumnsConfig, MAXIMUM_HISTORY_ITEMS } from '../constants/financialInstrumentHistoryConstants';
import { SectionHeader } from './common/Layout';

interface DebitCardsProps {
  cardData: {
    createdAt: string;
    disablementReason: string;
    expiration: string;
    numberFirst6: string;
    numberLast4: string;
    status: string;
  }[];
}

export default function DebitCards({ cardData = [] }: DebitCardsProps): ReactElement {
  const sortedCardData = orderBy(cardData, ['createdAt'], ['desc']);

  return (
    <DebitCardsWrapper>
      <SectionHeader>Debit Card</SectionHeader>
      <Table columnsConfig={debitCardsColumnsConfig} rows={sortedCardData} rowsPerPage={MAXIMUM_HISTORY_ITEMS} />
    </DebitCardsWrapper>
  );
}

const DebitCardsWrapper = styled('div')`
  margin-right: 36px;
`;
