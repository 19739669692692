import { MouseEvent } from 'react';
import styled from '@emotion/styled';
import { isNil } from 'lodash';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip';

import { toastSuccess } from 'src/helpers/toastHelpers';

interface CopyToClipboardWrapperProps {
  text?: string | null;
}

export const copyTextToClipboard = (textToCopy = ''): Promise<void> => navigator.clipboard.writeText(textToCopy);

export const copyTextToClipboardHandler = (text: string | undefined | null) => (event: MouseEvent<HTMLSpanElement>) => {
  event.stopPropagation();
  copyTextToClipboard(text || '').then(() => {
    toastSuccess('Copied to clipboard', { toastId: 'copyToClipboardToast', autoClose: 500 });
  });
};

export const CopyToClipboardByClick = ({ text }: CopyToClipboardWrapperProps) => (
  <Tooltip title="Click to copy" placement="top">
    <TextWrapper onClick={copyTextToClipboardHandler(text)}>{text}</TextWrapper>
  </Tooltip>
);

export const CopyToClipboardByIcon = ({ text }: CopyToClipboardWrapperProps) =>
  !isNil(text) ? (
    <CopyToClipboardWrapper>
      {text}&nbsp;&nbsp;
      <CopyIconWrapper onClick={copyTextToClipboardHandler(text)}>
        <ContentCopyIcon fontSize="small" />
      </CopyIconWrapper>
    </CopyToClipboardWrapper>
  ) : null;

const TextWrapper = styled('span')`
  padding: 7px 0;
  margin: -7px 0;
  cursor: pointer;
`;

const CopyToClipboardWrapper = styled('span')`
  align-items: center;
  display: flex;
`;

const CopyIconWrapper = styled('span')`
  &:hover {
    cursor: pointer;
  }
`;
