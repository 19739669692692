import { get, map } from 'lodash';

import { StyledTableCell, StyledTableRow, TableColumnConfigProps } from '../Table';
import { ReactElement } from 'react';

interface RowProps {
  columnConfig: TableColumnConfigProps[];
  item: any;
}

const getRowCells = (columnConfig: TableColumnConfigProps[], item: any): ReactElement => {
  return (
    <>
      {map(columnConfig, ({ attr, customColumnStyle }) => {
        const attrConfig = columnConfig.find(c => c.attr === attr);
        const value = (attrConfig?.customRenderer && attrConfig.customRenderer(item)) || get(item, attr);

        return (
          <StyledTableCell key={attr} style={customColumnStyle && customColumnStyle()}>
            {value}
          </StyledTableCell>
        );
      })}
    </>
  );
};

export default function Row(props: RowProps): ReactElement {
  const { columnConfig, item } = props;

  return (
    <StyledTableRow key={item.id}>
      {getRowCells(columnConfig, item)}
    </StyledTableRow>
  );
}
