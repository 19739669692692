import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { filter, find, get, isNil } from 'lodash';
import { styled } from '@mui/material';
import { useQuery } from '@apollo/client';

import CreatePayoutFromLedgers, { SelectedLedgersInfo } from './components/CreatePayoutFromLedgers';
import CreditAdjustments from '../common/DebitCreditAdjustments/CreditAdjustments';
import DebitAdjustments from '../common/DebitCreditAdjustments/DebitAdjustments';
import LedgerFilters from '../../filters/LedgerFilters';
import ReportDownloader from '../common/ReportDownloader';
import Table from 'src/components/table/Table';
import useFiltersData from 'src/context/FiltersContext';
import useLedgersData, { useLedgersDataLoader } from 'src/containers/account/hooks/useLedgersData';
import useUserData from 'src/context/UserContext';
import { Account } from 'src/constants/accountConstants';
import { ACCOUNT_BALANCES_QUERY } from 'src/services/apollo/queries/accountQueries';
import { ADDITIONAL_FILTERS_TYPE, FilterValuesType, REQUEST_ID_TYPE_KEY } from 'src/constants/filterConstants';
import { DEFAULT_ROWS_PER_PAGE } from 'src/constants/tableConstants';
import { getLedgerColumnsConfig } from './helpers/ledgerHelpers';
import { LedgerType } from '../../constants/ledgerConstants';

interface AccountLedgersProps {
  accountData: Account | null;
  additionalFilters: ADDITIONAL_FILTERS_TYPE;
  filters?: FilterValuesType | null;
  isLogicalAccount: boolean;
  onLedgerFiltersAction: (filterValues: ADDITIONAL_FILTERS_TYPE) => void;
}

const LEDGERS_CSV_HEADER_ROWS_CONFIG = {
  id: 'ID',
  type: 'Type',
  refType: 'Ref Type',
  refId: 'Ref Id',
  amount: 'Amount',
  currency: 'Currency',
  payoutId: 'Payout Id',
  availableBalance: 'Available Balance',
  createdAt: 'Created At',
  updatedAt: 'Updated At',
};

export default function AccountLedgers({
  accountData,
  additionalFilters,
  filters,
  isLogicalAccount,
  onLedgerFiltersAction,
}: AccountLedgersProps): ReactElement {
  const { onFilterAction } = useFiltersData();
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedLedgers, setSelectedLedgers] = useState<LedgerType[]>([]);
  const { loadData: loadLedgersData, data: ledgersData, isLoading } = useLedgersData();
  const { loadData: ledgersDataLoader } = useLedgersDataLoader();
  const { userAbility } = useUserData();

  const userHasPermissionsToCreatePayoutFromLedgers = userAbility.can('see', 'sensitiveInfo');
  const userHasPermissionsToCreateCreditEntry = userAbility.can('manage', 'extra');
  const userHasPermissionsToCreateDebitEntry = userAbility.can('manage', 'extra');

  const accountIdFromAccountData = get(accountData, 'id', '') as string;

  const { loading: balancesLoading, data: balancesResponse } = useQuery(ACCOUNT_BALANCES_QUERY, {
    variables: {
      accountId: accountIdFromAccountData,
    },
  });

  const accountBalances = get(balancesResponse, 'accountBalances', []);
  const availableBalance = get(find(accountBalances, { type: 'AVAILABLE' }), 'amount');
  const accountHasNoAvailableBalance = isNil(availableBalance);

  const requestIdType = get(filters, REQUEST_ID_TYPE_KEY, '');
  const accountId = get(filters, requestIdType, '');

  const items = useMemo(() => get(ledgersData, 'nodes') || [], [ledgersData]);
  const ledgersCount = get(ledgersData, 'count', 0);

  useEffect(() => {
    setCurrentPage(0);
    loadLedgersData({ filters, additionalFilters, isLogicalAccount, offset: currentPage * DEFAULT_ROWS_PER_PAGE });
  }, [additionalFilters, filters]);

  useEffect(() => {
    loadLedgersData({ additionalFilters, filters, isLogicalAccount, offset: currentPage * DEFAULT_ROWS_PER_PAGE });
  }, [currentPage]);

  const changePageHandler = useCallback((newPage: number) => {
    setCurrentPage(newPage);
  }, []);

  const debitAdjustmentsControl = userHasPermissionsToCreateDebitEntry ? (
    <DebitAdjustments disabled={isLoading} filters={filters} />
  ) : null;

  const creditEntryControl = userHasPermissionsToCreateCreditEntry ? (
    <CreditAdjustments disabled={isLoading} filters={filters} />
  ) : null;

  const onSelectAction = (selectedLedger: LedgerType) => {
    const selectedLedgerId = selectedLedger.id;
    const ledgerFromSelected = find(selectedLedgers, { id: selectedLedgerId });
    const modifiedLedgers = ledgerFromSelected
      ? filter(selectedLedgers, ({ id }) => id !== selectedLedgerId)
      : [...selectedLedgers, selectedLedger];

    setSelectedLedgers(modifiedLedgers);
  };

  const onRemoveLedgerAction = (selectedLedger: LedgerType) => {
    const selectedLedgerId = selectedLedger.id;
    const modifiedLedgers = filter(selectedLedgers, ({ id }) => id !== selectedLedgerId);

    setSelectedLedgers(modifiedLedgers);
  };

  const onSuccessfullyCreatedPayoutFromLedgers = () => {
    setSelectedLedgers([]);
  };

  const ledgerColumnsConfig = getLedgerColumnsConfig({
    accountHasNoAvailableBalance,
    isLogicalAccount,
    onFilterAction,
    onSelectAction,
    selectedLedgers,
    userHasPermissionsToCreatePayoutFromLedgers,
    editorRouteBase: `/account/${encodeURIComponent(accountId)}/type/${encodeURIComponent(requestIdType)}/ledger`,
  });

  return (
    <LedgersContainer>
      <FiltersWrapper>
        <LedgerFilters
          filters={additionalFilters}
          isLogicalAccount={isLogicalAccount}
          onAction={onLedgerFiltersAction}
        />
      </FiltersWrapper>
      <ContentWrapper>
        <Actions>
          <ActionButtons>
            {userHasPermissionsToCreatePayoutFromLedgers ? (
              <CreatePayoutFromLedgers
                accountId={accountIdFromAccountData}
                availableBalance={availableBalance}
                id={accountId}
                onSubmit={onSuccessfullyCreatedPayoutFromLedgers}
                removeLedger={onRemoveLedgerAction}
                requestIdType={requestIdType}
                selectedLedgers={selectedLedgers}
              />
            ) : null}
            {creditEntryControl}
            {debitAdjustmentsControl}
            <ReportDownloader
              additionalFilters={additionalFilters}
              disabled={!ledgersCount || isLoading}
              filters={filters}
              isLogicalAccount={isLogicalAccount}
              headerRowsConfig={LEDGERS_CSV_HEADER_ROWS_CONFIG}
              loadData={ledgersDataLoader}
              maxLimit={ledgersCount}
              reportType="Ledger"
            />
          </ActionButtons>
          <SelectedLedgersInfo selectedLedgers={selectedLedgers} />
        </Actions>

        <Table
          columnsConfig={ledgerColumnsConfig}
          isLoading={isLoading || balancesLoading}
          paginationControls={{
            count: ledgersCount,
            currentPage,
            onPageChange: changePageHandler,
          }}
          rows={items}
          rowsPerPage={DEFAULT_ROWS_PER_PAGE}
        />
      </ContentWrapper>
    </LedgersContainer>
  );
}

const LedgersContainer = styled('div')`
  display: flex;
`;

const Actions = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
`;

const FiltersWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding-top: 42px;
  margin-right: 12px;
  width: 350px;
`;

const ContentWrapper = styled('div')`
  width: 100%;
`;

const ActionButtons = styled('div')`
  display: flex;
  justify-content: flex-end;
`;
