import styled from 'styled-components';

import CobSchedules from './components/CobSchedules';
import IncentiveInfo from './components/incentiveInfo/IncentiveInfo';
import InfoList from 'src/components/infoList/InfoList';
import OrganizationInfo from './components/OrganizationInfo';
import PayoutStatus from 'src/containers/common/components/PayoutStatus';
import PriceProfileManagement from './components/pricingProfiles/PriceProfileManagement';
import { Account, AccountDataProps, CobSchedulesType } from 'src/constants/accountConstants';
import { FilledContainer } from 'src/components/layouts/Layout';
import { stringifyDate } from 'src/helpers/dateTimeHelpers';

interface AccountInfoProps {
  data?: Account | null;
}

const AccountAttrDefs = [
  {
    id: 'name',
    label: 'Name',
    customRender: (name: string) => <AccountHeader>{name}</AccountHeader>,
  },
  {
    id: 'id',
    label: 'ID',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'type',
    label: 'Type',
  },
  {
    id: 'enabledFeatures',
    label: 'Enabled Features',
    customRender: (features: string[] = []) => features.join(', '),
  },
  {
    id: 'createdAt',
    label: 'Created at',
    customRender: stringifyDate,
  },
  {
    id: 'updatedAt',
    label: 'Updated at',
    customRender: stringifyDate,
  },
  {
    id: 'accountOrganizations',
    label: 'Account Organizations',
    customRender: (organizationInfo: any) => <OrganizationInfo data={organizationInfo} />,
    isCollapsible: true,
  },
  {
    id: 'cobSchedules',
    label: 'Close Of Business',
    customRender: (schedules: CobSchedulesType[]) => <CobSchedules schedules={schedules} />,
    isCollapsible: true,
  },
  {
    id: 'incentives',
    label: 'Incentive',
    customRender: (_: any, data: Account) => <IncentiveInfo ownerId={data?.ownerId} />,
    isCollapsible: true,
  },
  {
    id: 'pricingProfiles',
    label: 'Pricing',
    customRender: (_: any, data: Account) => <PriceProfileManagement data={data} />,
    isCollapsible: true,
  },
  {
    id: 'payoutStatus',
    label: 'Payout Status',
    // TODO unify Account & AccountDataProps types
    customRender: (_: any, accountData: AccountDataProps) => <PayoutStatus data={accountData} />,
  },
];

export default function AccountInfo({ data }: AccountInfoProps) {
  return (
    <FilledContainer>
      <InfoList attrDefinitions={AccountAttrDefs} data={data} />
    </FilledContainer>
  );
}

const AccountHeader = styled.div`
  font-weight: 600;
  text-transform: uppercase;
`;
