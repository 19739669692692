import React, { ReactElement, useState } from 'react';
import { Box, FormControl, Select, MenuItem, InputLabel, TextField, Button } from '@mui/material';
import ConfirmationDialog from './components/ConfirmationDialog';
import logger from 'src/services/logger';
import apolloClient from 'src/services/apollo/apolloClient';
import { ASSIGN_OFFERING_OPTION_55_MUTATION } from 'src/services/apollo/mutations/priceProfileMutation';
import { toastAutoCloseError, toastSuccess } from 'src/helpers/toastHelpers';

const actions = [
  {
    label: 'Add Offering Option 55 To A Business',
    value: 'addBusinessToOO55',
    inputs: [
      {
        name: 'businessId',
        label: 'Business ID',
        type: 'text',
        placeholder: 'e.g. 868eea7d-9a36-4af2-b31f-f93eae12f0a5',
      },
    ],
  },
];

const PayfacTools: () => ReactElement = () => {
  const [selectedAction, setSelectedAction] = useState<string | null>('addBusinessToOO55');
  const [inputValues, setInputValues] = useState<Record<string, string>>({});
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);
  const [isResponseDialogOpen, setResponseDialogOpen] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState('');

  const handleActionChange = (value: string) => {
    setSelectedAction(value);
    setInputValues({}); // Reset inputs when the action changes
  };

  const handleInputChange = (name: string, value: string) => {
    setInputValues(prev => ({ ...prev, [name]: value }));
  };

  const handleExecute = async () => {
    setDialogOpen(true);
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);

      switch (selectedAction) {
        case 'assignPriceProfile':
          // code block
          logger.info(`Executing ${selectedAction} with inputs: ${JSON.stringify(inputValues)}`);
          break;
        case 'createBusinessPriceRule':
          // code block
          logger.info(`Executing ${selectedAction} with inputs: ${JSON.stringify(inputValues)}`);
          break;
        case 'addBusinessToOO55':
          {
            // code block

            const { data } = await apolloClient.mutate({
              mutation: ASSIGN_OFFERING_OPTION_55_MUTATION,
              variables: { businessId: inputValues.businessId },
            });

            const pricingResponse = data?.assignOfferingOption55?.response;
            // Simple check to see if the offeringOptionId was added to business
            const containsOfferingOption = pricingResponse.includes('offeringOption');

            if (!containsOfferingOption) {
              toastAutoCloseError('Offering Option 55 was not added to business.');
              return;
            }

            const str = JSON.stringify(JSON.parse(pricingResponse), null, '\t');

            setResponse(str);

            toastSuccess('Offering Option 55 was added to business.', { autoClose: 500 });

            setResponseDialogOpen(true);
          }
          break;
        default:
          // code block
          logger.error(`Action ${selectedAction} not found.`);
      }
    } catch (e) {
      // eslint-disable-no-empty
    } finally {
      setLoading(false);
      setDialogOpen(false);
    }
  };

  const handleCancel = () => {
    setDialogOpen(false);
  };

  const handleResponseConfirm = () => {
    setResponseDialogOpen(false);
  };

  const handleResponseCancel = () => {
    setResponseDialogOpen(false);
  };

  const selectedActionDetails = actions.find(action => action.value === selectedAction);

  return (
    <Box sx={{ p: 2, maxWidth: 400, mx: 'auto' }}>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="action-select-label">Select Action</InputLabel>
        <Select
          labelId="action-select-label"
          value={selectedAction || ''}
          onChange={e => handleActionChange(e.target.value as string)}
        >
          {actions.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {selectedActionDetails?.inputs.map(({ name, label, type, placeholder }) => (
        <TextField
          key={name}
          fullWidth
          label={label}
          type={type}
          placeholder={placeholder}
          value={inputValues[name] || ''}
          onChange={e => handleInputChange(name, e.target.value)}
          sx={{ mb: 2 }}
        />
      ))}

      <Button
        variant="contained"
        color="primary"
        fullWidth
        disabled={!selectedAction || !selectedActionDetails?.inputs.every(({ name }) => inputValues[name])}
        onClick={handleExecute}
      >
        Execute
      </Button>

      <ConfirmationDialog
        title="Confirm Action"
        description={`You are about to execute "${selectedActionDetails?.label}" with inputs: ${JSON.stringify(
          inputValues
        )}.`}
        isOpen={isDialogOpen}
        isLoading={isLoading}
        handleClose={handleCancel}
        handleConfirm={handleConfirm}
        confirmLabel="Confirm"
        cancelLabel="Cancel"
      />

      {/* Change this to response section later */}
      <ConfirmationDialog
        title="Response from Action"
        description={response}
        isOpen={isResponseDialogOpen}
        isLoading={isLoading}
        handleClose={handleResponseCancel}
        handleConfirm={handleResponseConfirm}
        confirmLabel="Confirm"
      />
    </Box>
  );
};

export default PayfacTools;
